/*=============== BASE ===============*/
* {
  margin: 0;
  padding: 0;
  font-family: var(--font-body-regular);
  font-size: 1em;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

body {
  transition: 1s;
  font-family: var(--font-body-regular);
  background-color: #f3f3f3;
}

ul,
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #fff;
  font-size: 15px;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
a:focus {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
p {
  font-size: 16px;
  line-height: 1.2em;
}

img {
  max-width: 100%;
}

/*=============== INPUT FIELD RESET ===============*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="radio"] {
  -webkit-appearance: none;
  border: 2px solid rgb(153, 153, 153);
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  margin: 2px;
  border-radius: 50%;
}
input[type="radio"]:checked:before {
  background: var(--primary-color);
}
input[type="radio"]:checked {
  border: 2px solid var(--primary-color);
}

.hidden {
  display: none;
}

input[type="checkbox"]:checked {
  background-color: var(--primary-color);
  outline: 0.15em solid var(--primary-color);
  border: 0.1em solid #fff;
}
input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: #666666;

  width: 1.15em;
  height: 1.15em;
  padding: 8px;
  border: 0.15em solid #666666;
  border-radius: 5px;
  transform: translateY(-0.075em);
  transition: 0.5s;
  display: grid;
  place-content: center;
  cursor: pointer;
}
input[type="checkbox"]::before {
  content: "";
  width: 0.7em;
  height: 0.7em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #fff;

  /* Windows High Contrast Mode */
  // background-color: red;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}


.btn{


 background-color: rgba(0, 128, 0, 0.322);

}

.statistic-cards{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.card-money{
  height: 150px;
  padding: 15px;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 15px;
  // background-color: #06041f;
  background: url("../../../../public/images/background_pattern.png") #ff6b00;
}

.withdraw-card{
  width: 100%;
}

.dark-color{
  color: #06041f !important;
}

.card-money .wallet-balance{
  display: flex;
justify-content: flex-start;
align-items: center;
}

.card-money p{
  color: white !important;
}
.card-money .wallet-balance h2{
  margin-top: 7px;
  font-size: 30px;
  color: white;
 
}

.card-money .wallet-balance i{
  margin-left: 20px;
  font-size: 18px;
  color: white;
 
}



// Loading component start here

.loadingbox{
  position: relative;
}

.loadingbox .loadingoverlay{
  position: absolute;
  background-color: rgba(10, 10, 1, 0.459);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 18px;
}

.loadingbox .loadingoverlay .loadingContent{

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  
}

.loadingbox .loadingoverlay .loadingContent i{

  font-size: 50px;
  color: #f3f3f3;
}

// Loading component ends here