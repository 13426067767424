.step-container {
  margin: 0 auto;
  width: 55%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.step-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.step-style {
  position: relative;
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid #dedfe5;
  background: #ffffff;
  transition: 0.4s ease;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.step-label {
  position: absolute;
  color: var(--step-txt-color);
  top: 100%;
}

.step-label-b {
  font-weight: 600;
  color: #140900;
}

.step-container::before {
  content: "";
  position: absolute;
  background: #d6d8df;
  height: 2px;
  width: 100%;
  top: 40%;
  transform: translateY(-50%);
  left: 0;
  z-index: -1;
}

.step-container:after {
  content: "";
  position: absolute;
  background: var(--primary-color);
  height: 2px;
  width: calc(var(--step-width) * 50%);
  top: 40%;
  transition: 0.4s ease;
  transform: translateY(-50%);
  left: 0;
  z-index: -1;
}

.step-style-1 {
  position: relative;
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  background: var(--primary-color);
  transition: 0.4s ease;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.step-active {
  position: relative;
  display: flex;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  background: #ffffff;
  transition: 0.4s ease;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

// PROFILE
.profile {
  width: 60%;
  margin: 40px auto;
  background-color: #fff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 2px 1px 3px rgba(233, 230, 230, 0.6);
}

.profile .title {
  margin-bottom: 2rem;
}

.profile div p {
  margin-bottom: 0.5rem;
  color: var(--step-txt-color);
}

.profile .nextBtn {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}

.profile .nextBtn button {
  cursor: pointer;
  padding: 9px 18px;
  border-radius: 10px;
  background: var(--primary-color);
  border: none;
  font-weight: 500;
  color: #fff;
}

.profile .nextBtn button:hover {
  background: #140900;
}

.profile .header h3 {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.docs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  padding: 15px 30px;
  margin-top: 1rem;
  margin-bottom: 0.6rem;
  border-radius: 5px;
}

.bs-plus {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0dccf;
  border-radius: 50%;
  // padding: 12px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.bs-plus.approved {
  background: #57c049;
}

.bs-plus.rejected {
  background: #d6402c;
}

.bs-plus.reviewing {
  background: #07b4e9;
}

//Modal
.modal {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  margin: 0 auto;
  width: 40%;
  max-height:80% ;
  overflow-y: scroll;
  height: fit-content;
  transform: translateY(-50%);
  background: #fff;
  padding: 30px 35px 20px 35px;
  border-radius: 12px;
  z-index: 20;
}

.modal .header {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.modal .desc {
  background: #f0dccf;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: var(--primary-color);
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}

.close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #acacac;
  border-radius: 0 5px 0 5px;
  width: 30px;
  height: 30px;
}

.close-icon:hover {
  background: var(--primary-color-lighten);
}

.modal .desc p {
  margin-left: 0.4rem;
}

.modal h4 {
  color: var(--step-txt-color);
}

// .modal .body {
//   max-height: 350px;
//   overflow-y: auto;
// }

.disable-scrollbars::-webkit-scrollbar {
  background: transparent; /* Chrome/Safari/Webkit */
  width: 0px;
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.modal .driver-license {
  margin-bottom: 2rem;
}

.modal .driver-license div:first-child {
  display: flex;
  align-items: center;
  background: #f9f9f9;
  padding: 12px 30px 12px 12px;
  margin-top: 1rem;
  margin-bottom: 0.6rem;
  cursor: pointer;
  border-radius: 5px;
}

.modal .driver-license div span {
  margin-right: 0.5rem;
}

.upload-label {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  padding: 35px 70px;
  width: fit-content;
  margin: 0 auto;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  color: var(--primary-color);
  border: 1px dashed var(--primary-color);
}

.upload-label-disabled {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  padding: 35px 70px;
  width: fit-content;
  margin: 0 auto;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  cursor: not-allowed;
  color: #acacac;
  border: 1px dashed #acacac;
}

// BVN (Compulsory) Modal
.modal .btn-bvn-submit {
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0;
}

.body-bvn {
  margin-top: 3rem;
}

.body-bvn label {
  font-weight: bold;
}

.body-bvn input {
  margin-top: 0.5rem;
  font-size: medium;
  font-weight: bold;
}

.body-bvn input::placeholder {
  font-weight: 500;
}

// Transaction Modal
.trans-modal {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  margin: 0 auto;
  width: fit-content;
  max-width: 40%;
  height: fit-content;
  transform: translateY(-50%);
  background: #fff;
  padding: 30px 35px 20px 35px;
  border-radius: 12px;
  z-index: 20;
}

.trans-modal .header {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.trans-modal .btn-trans-close {
  display: flex;
  justify-content: center;
  margin-bottom: .2rem;
  margin-top: .6rem;
}

.trans-modal .btn-trans-close button:hover {
  background: #d15700;
}

.body-trans {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;

}

.body-trans .trans-data {
  
  display: flex;
  justify-content: space-between;  
  align-items: center;
}

.body-trans .trans-data.code-section {

 flex-direction: column;
 justify-content: left;
 align-items: flex-start;


}

.body-trans .trans-data.code-section div:nth-child(2){
  width: 100%;
  text-align: left;
  background-color: black;
  color: green;
  padding: 15px;
  word-break: break-all;

 }



.body-trans  .trans-data .trans-status {
  text-transform: capitalize;
}

.body-trans .trans-data div {
margin-bottom: 2rem;
}


 .body-trans .trans-data div:first-child{
   width: 30%;
   text-align: left;
   font-weight: bolder !important;
   color: #747474;

  }


  .body-trans .trans-data div:nth-child(2){
    width: 70%;
    text-align: right;
   }



// .body-trans .left div {
//   color: #747474;
//   font-size: 15px;
//   text-align: left;
//   margin-bottom: 2rem;
// }


// .body-trans .right div {
//  text-align: right;
//  font-size: 15px;
//  margin-bottom: 2rem;
//  font-weight: bold;
// }

//VIDEO Modal
.body-video {
  text-align: center;
}

.body-video p {
  font-weight: bold;
}

.body-video .instruct {
  margin: 1rem auto;
  width: 90%;
  text-align: center;
}

.body-video .video-icon {
  padding: 10px 24px 2px 24px;
  background: #f9f9f9;
  border-radius: 5px;
  margin-top: 0.5rem;
}

.body-video .video-icon p {
  margin: 20px 0;
  color: red;
}

.body-video .video-icon .activeRecording {
  margin: 20px 0;
  color: rgb(5, 187, 5);
}

.record__btngroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  margin-top: 50px;
}

.modal .btn-upload {
  background: var(--primary-color);
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 12px 0;
  width: 100%;
  margin: 1.2rem 0 1rem 0;
}

.modal .btn-upload:disabled {
  background: #e0e0e0;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 12px 0;
  width: 100%;
  margin: 1.2rem 0 1rem 0;
  cursor: not-allowed;
  transform: scale(1);
}

.btn-record-video {
  background: var(--primary-color);
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 12px 0;
  width: 100%;
  margin: 0.2rem 0 1.5rem 0;
}

.btn-record-video:disabled {
  background: #d8d8d8;
  cursor: not-allowed;
}

.btn-record-video:disabled:active {
  transform: scale(1);
}

.word {
  text-transform: uppercase;
  font-weight: bolder;
}

#recorded-video, .live-display {
  width: 80%;
  margin: 0 auto;
}

.btn-retake-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  justify-content: space-between;
}

// Proof of Address
.modal .body-proof .upload label span {
  margin-right: 0.5rem;
}

.modal .body-proof div ul {
  list-style: square;
  margin-left: 1.1rem;
  margin-bottom: 1.5rem;
}

.modal .body-proof div li {
  margin-bottom: 1rem;
  font-size: 14px;
}

//Other Document
.modal .body-proof input {
  height: 25px;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.upload-files div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upload-files div div h5 {
  margin-left: 0.6rem;
}

.delete-icon {
  cursor: pointer;
}

#form-body {
  max-height: 350px;
  overflow-y: auto;
}

.input-wrapper {
  position: relative;
  margin-bottom: 1rem;
}

.errMsg {
  position: absolute;
  bottom: 0px;
  left: 3px;
  color: var(--error-msg);
  font-weight: 700;
  margin: 0 0;
  font-size: smaller;
}

.form-input-err {
  background-color: #fff;
  padding: 18px 27px;
  border: 1px solid var(--error-msg);
  outline: none;
  font-family: var(--font-body-medium);
  color: var(--text-color);
  height: 25px;
  width: 100%;
  border-radius: 5px;
  padding-left: 1rem;
  margin-bottom: 1rem;
}

.form-flex {
  display: flex;
  gap: 10px;
}

.form-flex .edit-profile {
  width: 50%;
}

/* Small Screens */
@media (max-width: 1000px) {
  .step-container {
    width: 70%;
  }

  .profile {
    width: 100%;
  }

  .modal {
    width: 70%;
  }
}

/* Smaller Screens */
@media (max-width: 768px) {
}

@media screen and (max-width: 480px) {
  .step-container {
    width: 100%;
  }

  .profile {
    width: 100%;
    // padding: 20px;
  }

  .step-label {
    font-size: small;
  }

  .docs {
    padding: 15px;
  }

  .modal {
    width: 90%;
    padding: 25px 30px 15px 30px;
  }

  .body-bvn {
    text-align: center;
  }

  .body-bvn label {
    text-align: center;
  }
}
