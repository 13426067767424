/* Dashboard */

.Update{
  background-color: rgb(189, 67, 67);
  height: 50px;
  color: white;
  padding: 12px;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 10;
}



.email-verify{
  font-size: 25px;
  margin-bottom: 30px;
}
.brand-flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.side-text{
  margin-left: 8px;
}

.brand-flex .close-icon{
  font-size: 30px;
  color: white;
  background-color: transparent;
  display: none;

}
.transac-table {
  overflow-x: auto;
}


.boxes {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.boxes .card {
  flex: 1;
}

.filter-d-flex{
  display: flex;

}

.filter-d-flex .btn{
  margin-right: 10px !important;
}



.boxes .card p {
  font-weight: 100;
}

.boxes .card p.box-p {
  font-size: 14px;
  margin-bottom: 25px;
}

.boxes .card h2 {
  margin: 15px 0;
  font-size: 1.4rem;
}

.boxes .dash-card:hover p, .boxes .dash-card:hover h2{
  color: white !important;
}

.prm-color a,
.prm-color {
  color: var(--primary-color);
  cursor: pointer;
}

.prm-color a i {
  margin-left: 5px;
}

.trade-view,
.transaction-table {
  margin-top: 30px;
}

.trade-view .card,
.transaction-table .card {
  max-width: 100%;
}

#myChart {
  margin: 40px 0;
  padding: 100px 0 0;
}

.trade-preview {
  display: flex;
  justify-content: space-between;
  align-items: center;



}

.trade-preview form{
  width: 30% ;
}



.year-option {
  display: inline-block;
  padding: 10px 10px;

  font-size: 14px;
  border-radius: 5px;
  border: 0.5px solid rgba(27, 28, 30, 0.25);
  color: #222;
  outline: none;
}

.filter-plus {
  position: relative;
}

.filter-plus .filter {
  border: 1px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  margin-right: 5px;
  cursor: pointer;
}

.filter i {
  margin-left: 5px;
}

.table {
  width: 100%;
  color: #333;
  padding: 10px 0;
  margin: 20px auto;
  border-collapse: collapse;
}

thead {
  background-color: #f3f3f3;
}

table th:first-child {
  border-radius: 10px 0 0 10px;
}

table th:last-child {
  border-radius: 0 10px 10px 0;
}

thead tr th {
  text-align: left;
  font-size: 14px;
  color: rgba(27, 28, 30, 0.5);
  margin: 0;
}

th,
td {
  font-weight: 400;
  font-size: 0.9em;
  padding: 20px;
}

tr {
  border-bottom: 1px solid rgba(132, 136, 148, 0.25);
}

table thead tr {
  border-bottom: 20px solid white;
}

table tbody .span {
  background-color: rgba(216, 129, 30, 0.336);
  border-bottom: none;
}

table tbody .trans {
  cursor: pointer;
}

table tbody .span td {
  padding: 10px;
  font-size: 16px;
}

table tbody .span .debit {
  color: red;
  font-size: 16px;
  font-weight: bolder;
}

table tbody .span .credit {
  padding-right: 20px;
  color: green;
  font-size: 16px;
  font-weight: bolder;
}

table tbody tr td .transaction_type {
  border: 1px solid rgb(26, 238, 26);
  padding: 6px 10px;
  border-radius: 4px;
  color: rgb(26, 238, 26);
  font-size: 12px;
}

table tbody tr td .transaction_type.trans-debit {
  border: 1px solid rgb(245, 50, 50);

  color: rgb(243, 63, 63);
}

table tbody tr td .transaction_type.trans-default {
  border: 1px solid rgb(105, 101, 101);

  color: rgb(105, 101, 101);
}

.kycflex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card.kycflex {
  padding: 20px !important;
}

.kycflex div h2 {
  margin-bottom: 5px;
  font-weight: bold;
  font-family: var(--font-body-bold);
}

.kycflex div p {
  font-size: 14px !important;
}

.emtyTransactions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
