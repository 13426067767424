/* Features */
.feat-icon {
  display: flex;
  align-items: center;
}

.feat-img {
  width: 25px;
  height: 25px;
  margin-right: 8px;
}

.feat-plus-img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.feat-text {
  font-size: 16px;
  margin-left: 0.8em;
}

.feat-inner-1 {
  margin-top: 1rem;
  margin-left: 3rem;
}

.feat-inner-1 p {
  margin-bottom: 2rem;
}

.feat-inner-2 {
  margin-top: 2rem;
  margin-left: 3rem;
}

.main {
  width:85%;
  margin-top: 70px;
  

}

.feature-board .feature-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  height: 70px;
  background-color: #fff;
  font-weight: 500;
  position: fixed;
  top: 50px;
  right: 0;
  width: 85%;
  z-index: 10;
 
}

.feature-board .showcase {
  margin-top: 70px;
  padding: 30px;
// overflow-x: hidden;
}

.showcase .get-user {
  margin-bottom: 30px;
}

.showcase .get-user h3 {
  font-size: 1.2rem;
}

.feature-head .flex-icon h3{
  font-size: 1.3rem;
}


.showcase .get-user p {
  font-size: 16px;
  line-height: 1.5rem;
}

.card {
  background-color: #fff;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 2px 1px 5px rgba(233, 230, 230, 0.6);
}

.dash-card:hover{
  background-color: #1B1C1E;
}

.feat-card {
  background-color: #fff;
  padding: 18px;
  border-radius: 15px;
  margin-bottom: 1.2rem;
  box-shadow: 2px 1px 5px rgba(233, 230, 230, 0.6);
}

.card.virtual-act {
  max-width: 350px;
}
.virtual-act .plus {
  margin-bottom: 40px;
}

.virtual-act .plus .small-circle {
  background: var(--secondary-color);
  font-size: 20px;
  padding: 10px 7px;
  border-radius: 50%;
  color: var(--primary-color);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.virtual-act .plus .plus-circle {
  border: 1px solid var(--primary-color);
  padding: 3px 7px;
  border-radius: 50%;
}

.virtual-act p {
  font-size: 0.9em;
  margin-top: 10px;
  max-width: 200px;
}

i {
  /* color: #fff; */
  margin-right: 5px;
}

i.fa-bell {
  // color: var(--primary-color);
  color: #1B1C1E;
  margin-right: 20px;
  font-size: 25px;
}
