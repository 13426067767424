.error-page{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.error-center{
   
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    h3{
        font-size: 44px;
        color: #828282;
        font-family: var(--font-body-medium);
    }

    

    .error-btn{
        background:var( --primary-color);
        color: white;
        padding: 12px 30px;
        border-radius: 25px;

    }

   
}

@media (max-width: 768px) {
    .error-center h3{
        font-size: 20px;
    }
}

