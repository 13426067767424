/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.cdnfonts.com/css/gilroy-bold?styles=20876,20877,20878,20879,20880");

:root {
  /* #ff6b00 same as hsl(25°, 100%, 50%)*/
  --hue: 25;
  --sat: 100%;
  --primary-color: #ff6b00;
  --primary-color-lighten: #ffb17a;
  --font-body-bold: "Gilroy-Bold", sans-serif;
  --font-body-heavy: "Gilroy-Heavy", sans-serif;
  --font-body-light: "Gilroy-Light", sans-serif;
  --font-body-medium: "Gilroy-Medium", sans-serif;
  --font-body-regular: "Gilroy-Regular", sans-serif;
  --secondary-color: #ff6b00;
  --text-color: #1b1c1e;
  --black-active-color: #1b1c1e;
  --black-inactive-color: #666;
  --scroll-bar-color: hsl(var(--hue), 4%, 85%);
  --scroll-thumb-color: hsl(var(--hue), 4%, 75%);
  --scroll-thumb-hover-color: hsl(var(--hue), 4%, 65%);
}

@mixin displayFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
