
/* Small Screens */
@media (max-width: 1000px) {
  .account {
    width: 98%;
  }

  .kycflex{
    flex-direction: column;
    align-items:flex-start;
  }

  .kycflex div{
   margin-bottom: 10px;
  }
 
  .sidebar,.navbar {
    width: 0%; 
    left: -1000px;
  }
  
  .main, .feature-board .feature-head {
    width:100%;
    padding: 10px;}

    .feature-board .showcase {

      padding: 10px;
    }
    
    .showcase .get-user h3 {
      font-size: 0.9rem;
    }

    .feature-head div span img,.feature-head div span h3{
      display: none;
    }
 

    .sidebar-toggle-active{

      width: 100%; 
      left: 0;
      .navbar{
        width: 100%; 
        left: 0;
        .brand .bar-logo{
          height: 50px;

        
        }

      }

    }
  // .navbar {
  //   left: -300px;
  //   margin-top: 70px;
  //   overflow-y: scroll;
  //   z-index: 1;
  //   transition: 0.3s;
  // }

  // .feature-board {
  //   margin-left: 0;
  // }

  // .feature-board .feature-head {
  //   left: 0;
  //   padding-left: 60px;
  //   z-index: 1000;
  // }

  #get-menu {
    display: inline-block;
    font-size: 1.2rem;
    margin-right: 30px;
    color: #333;
    cursor: pointer;
  }

  .toggle-filter-active {
    left: 0px;
  }

  
}

/* Smaller Screens */
@media (max-width: 768px) {

  .brand-flex .close-icon{
    display: block;
  }
  .boxes{
    flex-direction: column;
  }

  .boxes .dash-card{
    width: 100%;

  }

  
  .body-trans .trans-data {
  flex-direction: column;
  }
  .body-trans .trans-data div {
    margin-bottom: 1rem;
    }
  .body-trans .trans-data div:first-child{
    width: 100%;
   
 
   }
 
 
   .body-trans .trans-data div:nth-child(2){
     width: 100%;
     text-align: left;
    }
 

  .modal-content p.title {
   font-size: 25px !important;
  }

  .success-modal {
   
    top: 0;
    margin: 0 auto;
    width: 98%;
    transform: translateY(0%);
    background: #fff;
    padding: 30px 35px 20px 35px;
    border-radius: 15px;
    z-index: 20;
  }
  .statistic-cards{
    flex-direction: column;
  }
  .card-money{
    height: 120px;
    width: 100%;
   
  }

  .div-otp-flex input{
    width: 60%;
  }
  
  .div-otp-flex button{
    margin-left: 10px;
    width: 40%;
    padding: 15px 10px;
    font-size: 14px;
    color: #222;
    border: 1px solid #222;
    background-color: white;
  }
 
  .settings-nav {
    flex-direction: column;
    gap: 20px;
  }


  .settings-nav li a.setting-active {
    width: 100%;
    padding: 10px;
    font-weight: bold;
    transition: none;
    border-bottom: 5px solid var(--primary-color);
  }

  .account {
    width: 98%;
  }
  // .feature-head p {
  //   font-size: 15px;
  // }

  // img {
  //   height: 30px;
  //   width: 30px;
  // }

  .trade-preview {
   flex-direction: column;
   gap: 20px;
  }
  .trade-preview form, .trade-preview .filter-plus{
    width: 100% ;
  }
  
  
  .trade-preview h2 {
    margin-bottom: 20px;
  }

  .transac-table {
    overflow-x: auto;
  }

  .transac-table .table {
    font-size: 13px;
  }

  td {
    padding: 10px;
  }

  .filter-plus {
    width: 100%;
  }

  .filter-form {
    right: 0;
  }

  i.fa-bell {
    margin-right: 10px;
    font-size: 17px;
  }

  .img-bell-name .fa-angle-down {
    display: none;
  }

  #get-menu {
    margin-right: 20px;
  }

  .flex-number p {
    margin: 0px 10px;
  }

  .grid {
    display: block;
  }

  .grid .chk-bal,
  .grid .amt-tf,
  .grid .success-withdraw {
    margin: 20px 0px;
  }

  .showcase .get-user h3 {
    font-size: 0.9rem;
  }

  .showcase .get-user p {
    font-size: 14px;
  }

  .two.mr-lt {
    margin-left: 50px;
  }

  .two.mr-lt2 {
    margin-left: 10px;
  }

  .mr-lt {
    margin-left: 5px;
  }

  .settings-nav li a {
    font-size: 1em;
  }

  .info .flex {
    flex-direction: column;
  }

  #bank-form {
    right: 20px;
    left: 10px;
  }

  #bank-form2 {
    right: 20px;
    left: 10px;
  }
}

@media screen and (max-width: 480px) {
  .create-act h5 {
    font-family: var(--font-body-medium);
    font-size: 20px;
    margin-bottom: 7px;
    text-align: center;
    line-height: 33px;
  }

  .form-container h2 {
    font-size: 30px;
  }
}