

/*=============== SCROLL BAR ===============*/
::-webkit-scrollbar {
  width: 0.6rem;
  border-radius: 0.5rem;
  background-color: var(--scroll-bar-color);

  &-thumb {
    background-color: var(--scroll-thumb-color);
    border-radius: 0.5rem;

    &:hover {
      background-color: var(--scroll-thumb-hover-color);
    }
  }
}

