.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.kyc-modal {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
  margin: 0 auto;
  width: 40%;
  transform: translateY(-50%);
  background: #fff;
  padding: 30px 35px 20px 35px;
  border-radius: 15px;
  z-index: 20;
}

.kyc-modal header {
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
}

.kyc-modal header span:first-child {
  text-align: center;
  width: 100%;
}

.kyc-modal header span h3 {
  width: 100%;
  text-align: center;
}

.kyc-modal div {
  margin-bottom: 2.5rem;
}

.kyc-modal div p:first-child {
  text-align: center;
  margin-bottom: 2rem;
}

.kyc-modal div p:nth-child(2) {
  margin-bottom: 1rem;
}

.btn-continue-wrapper {
  text-align: center;
  margin: 0 0;
  padding: 0 0;
}

.business {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  height: 180px;
  padding: 0 1rem;
  margin: 1.5rem 0;
}

.logo {
  background: #eaeaea;
  padding: 2.5rem;
  border-radius: 999px;
  margin-right: 2rem;
}

.logo-2 {
  /* padding: 1.5rem; */
  border-radius: 999px;
  margin-right: 2rem;
}

.upload {
  text-align: center;
}

.upload h2 {
  margin-bottom: 0.8rem;
}

.upload button label {
  padding: 0.7rem 0.95rem;
  cursor: pointer;
}

.img-icon {
  height: 62px;
  width: 62px;
  border-radius: 0;
}

.img-icon-2 {
  height: 154px;
  width: 154px;
  /* border-radius: 0; */
}
.upload-doc {
  border-radius: 5px;
  background-color: #fff;
  width: 60%;
  padding: 15px 27px;
  border: 2.5px dashed#ccc;
  outline: none;
  text-align: center;
  font-weight: bold;
  font-family: var(--font-body-medium);
  color: var(--text-color);
}

.upload-doc label {
  color: var(--secondary-color);
  cursor: pointer;
}

.business-owner {
  margin: 3.5rem 0 3rem 0;
}

.business-owner h2 {
  margin-bottom: 2rem;
}

.business-owner .name {
  display: inline-flex;
  justify-content: space-between;
  width: 60%;
}

.business-owner .name input {
  width: 100%;
}

.business-owner .name div:first-child {
  margin-right: 1rem;
  /* border: 1px solid #ff6b00; */
}

.name div input {
  margin-top: 0.5rem;
}

.btn-save-changes-container {
  display: flex;
  /* justify-content: end; */
}
.btn-upload {
  padding: 10px 0;
  color: #f3f3f3;
  background: var(--secondary-color);
  border: none;
  font-size: 15px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
}

.btn-upload:active {
  transform: scale(0.95);
}
