
  .modal-content {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
  }
  
 .modal-content p.title {
    text-align: center;
    font-size: 30px ;
    font-weight: bolder;
    margin-top: 1.5rem;
  }
 .modal-content p.text{
    text-align: center;
    font-size: 18px ;
    font-weight: bold;
    margin-top: 0.8rem;
  }
  .modal  .btn-wrapper{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
 
  }

  .modal  .btn-wrapper .btn-full{
   margin-top: 7px;
 
  }

  

 