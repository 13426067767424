/* Account Creation */

.form-container {
  // background-image: url("../../../../public/images/background_pattern.png");
  // background-size: 100%;
  background-color: #1B1C1E;
  background-repeat: repeat-y;
  background-position: top left;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}





.new-Business{
  
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;
  position: relative;
}


.form-container-logo {
  width: 250px;
  margin-bottom: 40px;
}

.form-container h2 {
  font-family: var(--font-body-bold);
  font-size: 35px;
  margin: 22px 0;
  color: #fff;
}

.form-container p {
  text-align: center;
  font-size: 13px;
  opacity: 0.85;
  margin: 10px 0;
  font-family: var(--font-body-medium);
  color: var(--text-color);
  font-weight: 500;
  line-height: 19px;
  font-size: 15px;
  color: var(--text-color);
}

.account {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media screen and (min-width: 521px) {
    width: 500px !important;
  }
  color: #333;
}

.create-act {
  width: 100%;
}
.create-act h5 {
  font-family: var(--font-body-medium);
  font-size: 25px;
  margin-bottom: 7px;
  text-align: center;
  line-height: 33px;
}

.act-form,
.edit-profile {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  background-color: #fff;
}

.act-form .form-input-flex {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.form-input,
.edit-profile input,
.add-bank input,
.add-bank select {
  background-color: #fff;
  padding: 18px 27px;
  height: 50px;
  border: 1px solid #ccc;
  outline: none;
  font-family: var(--font-body-medium);
  color: var(--text-color);
}
.form-textarea {
  resize: none;
  background-color: #fff;
  padding: 18px 27px;
  font-size: 16px;
  border: 1px solid #ccc;
  outline: none;
  font-family: var(--font-body-medium);
  color: var(--text-color);
}
.phoneinput__container {
  position: relative;
  width: 100%;
}
.innerPasswordInputcontainer {
  position: relative;
}

.phoneinput__container-phone {
  padding-left: 100px;
  border: 1px solid #ccc;
}
.country-code {
  position: absolute;
  font-family: var(--font-body-medium);
  font-weight: 500;
  padding-left: 23px;
  height: 100%;
  border-right: 1px solid #ccc;
  display: grid;
  place-items: center;
  padding-right: 23px;
}
.password__eye {
  position: absolute;
  display: grid;
  place-items: center;
  height: 100%;
  right: 30px;
  cursor: pointer;
}
.password__eye-error {
  bottom: 0px;
}

.formsubmit__btn {
  font-size: 18px;
  font-family: var(--font-body-medium) !important;
}
.form-input.form-invalid-input {
  border: 1px solid rgb(238, 73, 73);
}

.form-invalid-feedback {
  font-size: 14px;
  color: rgb(238, 73, 73);
  margin-top: 10px;
}

.form-input-label {
  color: rgba(32, 14, 50, 0.5);
  font-size: 15px;
  padding-bottom: 10px !important;
  font-family: "Ubuntu", sans-serif;
}
.p-relative {
  position: relative;
}

.form-input-append {
  position: absolute;
  right: 15px;
  font-size: 20px;
  top: 12px;
  color: rgba(32, 14, 50, 0.4);
}

.form-input:focus,
.edit-profile input:focus,
.add-bank input:focus,
.add-bank select:focus {
  border-color: var(--secondary-color);
  transition: 0.5s;
}

.create-act .btn {
  width: 100%;
  height: 50px;
  margin: 10px 0px;
}

#get-menu {
  display: none;
}

@media screen and (max-width: 768px) {
  .form-container-logo {
    width: 200px;
    margin-bottom: 40px;
    height: 75px;
  }
}
