.support-bg-container{
    margin-top: 50px;
    // background-color: var(--primary-color);
    height: 300px;
    color: white;
    background: linear-gradient(to bottom, #412701b6, #dd7907bd),url("https://cdn.ttgtmedia.com/visuals/searchCRM/customer_experience/CRM_article_028.jpg");
 padding: 15px;
    background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;

}

.support-bg-container h2{
    font-size: 35px;
    font-weight: bolder;
    margin-bottom: 10px;
    font-family: var(--font-body-bold);
    
}

.support-bg-container p{
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    font-family: var(--font-body-medium);
   
    
}

.support-option{
    position: absolute;
    margin-top: -50px;
    z-index: 1;
    right: 0;
    
    //  background-color: red;
    
    width: 85%;
}


.support-card-div{

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    width: 50%;
    
}


.support-single{
    margin-bottom: 15px;
    border-radius: 15px;
    padding: 20px;
    width: 80%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;

}



.support-single .icon{
    height: 50px;
    width: 50px;
    background-color: rgba(184, 99, 2, 0.507);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.support-single .icon i{
    
    font-size: 25px;
    color: white;
}

.support-single  .button .button-link{
    padding: 10px 5px;
    border: none;
    border-radius: 5px;
    width: 100px;
    background-color:black ;
    color: white;
    font-size: 14px;
    text-decoration: none;
}


.support-single .content{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
}


.support-single .content h2{
   font-size: 18px;
   margin-bottom: 7px;
   font-family: var(--font-body-medium);
}

.support-single .content p{
   line-height: 1.7;
 }
 


@media (max-width: 1500px) {

    .support-option{

      position: static;
      margin-top: 20px;
      width: 100%;
      
    }

    .support-single{
       
        width: 95%;
     
    
    }

    .support-card-div{

        width: 100%;
      
    }
    

    .support-bg-container{
        height: 250px;
        position: static;
    }

    .support-bg-container h2{
        font-size: 30px;
        
        
    }
    
    .support-bg-container p{
        font-size: 15px;
      
    }

}