/* Utilities */
::selection {
  background-color: var(--secondary-color);
  color: #fff;
}

.btn-warn,
.btn-danger,
.btn-success,
.btn-blue,
.btn-lemon,
.btn-pink {
  color: #df7645;
  background-color: rgba(236, 171, 3, 0.2);
  font-size: 10px !important;
  line-height: 14px !important;
  font-weight: 600;
  text-align: center;
  padding: 5px 12px !important;
  border-radius: 3px !important;
  border: none !important;
}

.btn-warn.two,
.btn-danger.two,
.btn-success.two {
  background-color: #fff;
}

.btn-danger {
  color: #eb5757;
  background-color: rgba(235, 87, 87, 0.1);
}

.btn-success {
  color: #1eb12d;
  background-color: rgba(30, 177, 45, 0.1);
}

.btn-blue {
  background-color: rgba(69, 126, 148, 0.699);
  color: white;
}

.btn-pink {
  background-color: pink;
  color: palevioletred;
}

.btn-lemon {
  background-color: rgb(8, 119, 141);
  color: rgb(209, 209, 233);
}

.btn {
  padding: 10px 20px;
  border: none;
  font-size: 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  text-align: center;
}

.btn-full{
  margin-top: 20px;
  padding: 15px 20px;
  font-size: 18px;
  width: 100%;

}

.btn:active {
  transform: scale(0.95);
}

.btn-prm {
  background-color: var(--primary-color);
  border: none;
  color: #fff;
}

.btn-prm:disabled {
  background: #d8d8d8;
  cursor: not-allowed;
}

.btn-prm:disabled:active {
  transform: scale(1);
}

// Added 12/3/2023 ====START====
.btn-proceed {
  padding: 0.5rem 3rem;
}

.btn-feat-container {
  display: flex;
  flex-direction: column;
  margin: -0.5rem;
}

.btn-save-changes-container {
  display: flex;
  /* justify-content: end; */
}

.btn-resend {
  padding: 10px;
  border: none;
  font-size: 15px;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
  font-weight: 500;
  background: transparent;
  transition: 0.3s;
  color: #ff6b00;
}

.btn-goback {
  padding: 10px;
  border: none;
  font-size: 15px;
  border-radius: 4px;
  cursor: pointer;
  text-align: left;
  font-weight: 500;
  background: transparent;
  transition: 0.2s;
}

.btn-upload {
  padding: 10px 0;
  color: #f3f3f3;
  background: var(--secondary-color);
  border: none;
  font-size: 15px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
}

.btn-upload:active {
  transform: scale(0.95);
}

// ====STOP====

.active-two {
  color: var(--primary-color);
}

.transac-search {
  background-color: #f3f3f3;
  color: var(--primary-color);
  border: none;
  padding: 15px;
  font-size: 15px;
  border-radius: 7px;
  outline: none;
  width: 100%;
}

.page-number {
  margin-top: 30px;
  margin-bottom: 10px;
}

.flex-number {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-number p {
  margin: 0px 20px;
  font-weight: 600;
  cursor: pointer;
}

td span {
  max-width: 10px;
}

.next {
  border: 1px solid #ccc;
  padding: 3px 5px;
  border-radius: 3px;
  cursor: pointer;
}

.status-icon {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #222;
}

td i.next {
  color: #333;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.grid .chk-bal {
  grid-column: 1 / span 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: wrap; */
}

.flex.refund {
  gap: 20px;
  flex: 1;
  flex-wrap: wrap;
}

.flex .card {
  flex: 1;
}

.activity-board {
  margin: 50px 0px;
}

.show-activity {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 120px 0;
}

.show-activity svg {
  margin-bottom: 30px;
}

.show-activity svg.dispute-pic {
  border-left: 7px solid var(--primary-color);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-12 {
  margin-top: 12px;
}

.mb-4 {
  margin-bottom: 4px;
}
