/* Forms */

.div-otp-flex{
  display: flex;
  justify-content: flex-start;
  align-items: center;

}

.label-flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-flex{
  span{
    font-size: 14px;
  }
}

.div-otp-flex input{
  width: 70%;
}

.div-otp-flex button{
  margin-left: 10px;
  width: 20%;
  padding: 13px 20px;
 
  color: #222;
  border: 1px solid #222;
  background-color: white;
}

.filter-form {
  display: none;
  position: absolute;
  right: 120px;
  background-color: #fff;
  min-width: 280px;
  z-index: 1000;
  box-shadow: 0px 3px 7px rgba(182, 179, 179, 0.6);
}

.api-flex{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.api-flex .p-relative{
  width: 100%;
}

.api-flex .api_copy{
  // width: 5%;
  background:var( --primary-color);
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 7px;
  padding-right: 5px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
 
}
.api-flex .api_copy i{
  font-size: 18px;
}


.form-card {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 1px 1px rgba(90, 89, 89, 0.1);
}

.toggle-filter {
  display: block;
}

.filter-set label,
.dev-form label {
  display: block;
  margin: 10px 0px 5px 0px;
  font-size: 0.9em;
  font-weight: 500;
  opacity: 0.7;
}

.filter-form .btn {
  // width: 100%;
  margin: 0px 0px 10px 0px;
}

.btn-secondary {
  background-color: var(--black-active-color);
  color: #fff;
}

.btn-secondary:disabled {
  background-color: var(--black-inactive-color);
  color: #fff;
}

.btn-primary{
  background-color: var(--primary-color);
  color: #fff;

}

/* .btn-secondary:hover {
      background-color: var(--black-inactive-color);
  } */
.act-form label {
  width: fit-content;
}

.filter-set .flex input,
.act-form .flex input,
.edit-profile .flex input {
  border-radius: 0px;
}
.filter-set .flex input:nth-of-type(1) {
  border-right: 1px solid var(--primary-color);
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}
.filter-set .flex input:nth-of-type(2),
.act-form .form-input-flex input:nth-of-type(2),
.edit-profile .flex input:nth-of-type(2) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.filter-set input,
.filter-set select,
.dev-form-input input,
.dev-form-input-two,
.form-input,
.edit-profile input,
.add-bank input,
.add-bank select {
  width: 100%;
  padding: 10px;
  outline: none;
  background-color:#f3f3f3;
  border: none;
  font-size: 15px;
  color: #969494;
  border-radius: 5px;
}

.dev-form-input input {
  background-color: #fff;
  padding: 13px 10px;
  margin: 20px 0;
}

.dev-form-input-two {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 10px;
  margin: 20px 0;
}

.dev-form-input-two input {
  background-color: #fff;
  width: 100%;
  outline: none;
  border: none;
  color: #969494;
}

.dev-form-input-two i {
  cursor: pointer;
}

.otp-form {
  border-radius: 5px;
  background-color: #fff;
  padding: 18px 27px;
  height: 50px;
  border: 1px solid #ccc;
  outline: none;
  font-weight: bold;
  font-family: var(--font-body-medium);
  color: var(--text-color);
}

.otp-form::placeholder {
  font-weight: lighter;
}

.otp-form:focus {
  border-color: var(--secondary-color);
  transition: 0.5s;
}

.act-feat {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.phoneinput-feat__container {
  position: relative;
  width: 40%;
}

.phoneinput-bus__container {
  position: relative;
  margin-top: 0.5rem;
  width: 60%;
}

.select-bank {
  display: inline-block;
  padding: 15px 0px 15px 20px;
  width: 60%;
  font-size: 14px;
  border-radius: 5px;
  font-weight: bold;
  border: 0.5px solid rgba(27, 28, 30, 0.25);
  color: #222;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  background-image: url("https://www.svgrepo.com/show/80156/down-arrow.svg");
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: calc(100% - 30px);
}

.select-bank:focus {
  border-color: var(--secondary-color);
  transition: 0.5s;
}

.account-no-form {
  border-radius: 5px;
  background-color: #fff;
  width: 60%;
  padding: 18px 27px;
  height: 50px;
  border: 1px solid #ccc;
  outline: none;
  font-weight: bold;
  font-family: var(--font-body-medium);
  color: var(--text-color);
}

.account-no-form::placeholder {
  font-weight: lighter;
}

.account-no-form:focus {
  border-color: var(--secondary-color);
  transition: 0.5s;
}

.checkboxGroup {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}


.term-condition-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.filter-radio{
  margin-bottom: 20px;
}

.filter-divider{
  width: 100%;
  height: 1px;
  background-color: rgba(202, 195, 195, 0.6);
}

.form-select{
  padding: 0 !important;
}

.form-select-input{
  background-color: #fff;
  padding: 18px 27px;
  border: 1px solid #ccc;
  outline: none;
  font-family: var(--font-body-medium);
  color: var(--text-color);
}

select.form-select-input option:selected {
  color: red;
}