/* SETTINGS */

.card.setting {
  border-radius: 10px;
}

.settings-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.settings-nav li a {
  color: #000;
  font-size: 1em;
}

.settings-nav li a.setting-active {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 107, 0, 0.6) 126.92%

  );
  transition: 1s ease-out;
  font-weight: bolder;
  padding: 20px 50px;
  border-bottom: 5px solid var(--primary-color);
}

.profile {
  margin-top: 30px;
}

.setting-profile {
  // max-width: 450px;
  margin: auto;
}

.setting-profile .flex:first-child {
  gap: 50px;
}

.setting-profiletwo .flex {
  align-items: flex-start;
  justify-content: center;
  gap: 50px;
}

/* .setting-profiletwo h2 {
      margin: auto;
  } */

.setting-profile h2,
.setting-profiletwo h2 {
  font-size: 25px;
  text-align: center;
  margin: 10px;
}

.info .flex {
  margin: 30px 0;
  align-items: center;
  justify-content: center;
}

.info .flex .upload h3 {
  font-size: 17px;
}

.empty-logo {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
  
}



.edit-profile.two {
  margin: 0px;
}

.edit-profile,
.edit-profile.two,
.add-bank {
  margin: 10px 0;
}

.mg-top {
  margin-top: 40px;
}

.edit-profile input {
  color: #000;
  padding: 15px;
}

.add-bank input,
.add-bank select {
  color: #000;
}

.edit-profile.two input {
  width: 70%;
}

.edit-profile label,
.add-bank label {
  display: block;
  opacity: 0.5;
  margin: 7px 0px;
}

.btn-dk {
  background-color: #ccc;
}

.mr-center {
  margin: 20px 0;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.flex.table2 {
  margin: 30px 0px;
  box-shadow: 2px 1px 5px rgba(233, 230, 230, 1);
}

.bank-details {
  width: 400px;
  box-shadow: 2px 1px 5px rgba(233, 230, 230, 1);
  position: relative;
  display: none;
}

.close-bank-form {
  position: absolute;
  top: 30px;
  right: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.my-abs {
  position: relative;
}

#bank-form {
  position: absolute;
  right: 300px;
  bottom: 20px;
}

#bank-form2 {
  position: absolute;
  right: 350px;
}

/* .table-row-card {
      margin: 30px 0px;
      box-shadow: 2px 1px 5px rgba(233, 230, 230, 1);
      height: 100px;
      margin: 30px;
      
  } */

/* Profile Preference */

.pay-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 2px 1px 5px rgba(233, 230, 230, 1);
  margin: 20px 0px;
}
