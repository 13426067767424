/* Navbar */
/* width */
::-webkit-scrollbar {
  width: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #f3f3f3; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f3f3f3; 
}

.img-bell-name {
  display: flex;
  align-items: center;
  height: 100%;
}

.img-bell-name .position-dropdown {
  position: relative;
}

.img-bell-name .fa-angle-down {
  cursor: pointer;
}

.user-nav {
  display: none;
  position: absolute;
  right: 5px;
  margin: 10px 0;
  background-color: white;
  padding: 10px;
  z-index: 100;
  transition: 2s;
  width: 150px;
}

.user-nav li {
  padding: 10px;
  border-bottom: 1px solid #fff;
}

.user-nav li a {
  color: rgb(0, 0, 0);
}

.user-nav li:last-child {
  border-bottom: none;
}

.flex-icon {
  // display: inline-flex;
  display: flex;
  align-items: center;
}

.nav-icon {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}

.img-bell-name p {
  margin-left: 10px;
}

.fa-angle-down {
  margin-left: 5px;
}

.nav-feature {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar{

  width: 15%; 

}

.navbar {
  background: url("../../../../public/images/mask-overlay.png");
  background-color: #1B1C1E;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  padding: 20px 10px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  width: 15%;
  transition: 1s ease-in-out;
  overflow-y: auto;
  top: 50px;
}

.navbar .brand{
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .brand .bar-logo{
  height: 60px;
  margin-bottom:  15px;

}

.nav-list ul li {
  margin: 25px 0;
}

.nav-list ul li a {
  font-weight: 100;
}

.nav-list .active {
  
  background-color: var(--primary-color);
  border-radius: 4px;
  padding-top: 10px !important;
  
}

.nav-list ul li.active a {
  color: white;
}

/* .nav-list ul li.active a .nav-icon {
      background-color: var(--primary-color);
  } */

.nav-list ul li.active {
  padding: 6px;
}

.nav-list ul.nav-list-first li:nth-child(2) a,
.nav-list ul.nav-list-second li:nth-child(1) a {
  position: relative;
}


.nav-list ul.nav-list-second li:nth-child(1) a::before {
  content: "";
  position: absolute;
  margin: auto;
  width: 200px;
  left: -25px;
  right: 5px;
  bottom: -7px;
  height: 1px;
  border-bottom: 1px solid #fff;
}

.nav-list ul.nav-list-second li:nth-child(1) a::before {
  bottom: 0;
  top: -70px;
}

.nav-list {
  // min-height: 85vh;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: space-between;
}

.businessName{
  background-color: #28292b;
  padding: 15px 10px;
  margin: 0;
  border-radius: 5px;

  display: flex;
  flex-direction: column;


  .activebusiness{
    display: flex;
    justify-content: space-between;
    align-items: center;

    div{
      width: 90%;

      span{
        white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bolder;
    font-size: 14px;
    line-height: 22px;
    color: white;
    display: block;
    margin-top: 5px;
    
      }
    }
    i{
      font-weight: medium;
      font-size: 1.2rem;
      color: white;
      width: 10%;
  
    }

  }

  .divider{
    margin-top: 10px;
    width: 100%;
    height: 0.1px;
    background-color: rgba(199, 191, 191, 0.685);
    
  }

  .businesslist{
    
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    
   
    span{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 100;
      font-size: 16px;
      margin-top: 18px;
      color: white;
      text-transform: lowercase;

      &:first-letter{
        text-transform: uppercase !important;
      }

      &:hover{
        // background-color: white;
        // padding: 10px 5px;
        color: rgba(199, 191, 191, 0.685);
        border-radius: 5px;
      }
     

    
      
    }

  }

 
}

.businessmenu{
  display: none;


}


.businessmenu.active{
  transition: 3s ease-out;
  display: block;

  
}




