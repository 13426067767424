.formtwo__title {
  text-align: center;
  font-size: 18px;
  margin: 20px 0px 40px;
}

.newbussiness__title {
  text-align: center;
  font-size: 24px;
  font-weight: bolder;
  margin: 20px 0px 40px;
}
.button__group {
  margin-top: 30px;
  display: flex;
  gap: 5rem;
  button:first-child {
    background-color: #f0f0f0;
    font-weight: bold;
    color: var(--text-color);
  }
}

.css-b62m3t-container {
  width: 100% !important;
}

.state-lga {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.container__border {
  border: 1px solid var(--primary-color);
}
.accounttype {
  display: flex;
  margin-top: 30px;
  gap: 2rem;
  &__container {
    // transition: 3s;
    width: 100%;
    background-color: #f8f8f8;
    height: 100%;
    overflow: hidden;
    border-radius: 10px;
    padding: 20px;
  }
  &__heading {
    display: flex;
    align-items: center;
    gap: 1rem;
    line-height: 1.5;
    color: var(--text-color);
    font-weight: bold;
  }
  &__content {
    display: none;
    transition: 1s;
    margin: 10px 40px;
    &-title {
      font-size: 14px !important;
      font-weight: bold !important;
      text-align: initial !important;
      margin: 10px 30px;
    }
  }
  &__description {
    display: flex;
  }
  &__offers {
    display: flex;
    align-items: center;
    gap: 10px;
    &-img {
      width: 17px;
    }
    &-title {
      font-size: 14px !important;
      font-weight: bold !important;
      text-align: initial !important;
    }
  }
}

.open__container {
  display: block;
  animation: growDown 400ms ease-in-out forwards;
  transform-origin: top center;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@media screen and (max-width: 480px) {
  .button__group {
    margin-top: 30px;
    gap: 2rem;
  }

  .accounttype__content {
    margin: 15px 10px;
  }

  .state-lga {
    flex-wrap: wrap;
    gap: 2rem;
  }
}
